import React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import BtnTransparent from "../components/buttons/transparent"
import BtnPrimary from "../components/buttons/primary"
import modelsJson from "../data/models.json"
import ModelsCard from "../components/modelsCard"

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <div className="masthead relative">
        <div className="masthead hidden lg:block">
          <GatsbyImage
            image={getImage(
              data.allMdx.edges[0].node.frontmatter.masthead_desktop
            )}
            alt={data.allMdx.edges[0].node.frontmatter.title}
            placeholder="blurred"
          />
        </div>
        <div className="masthead block lg:hidden">
          <GatsbyImage
            image={getImage(
              data.allMdx.edges[0].node.frontmatter.masthead_mobile
            )}
            alt={data.allMdx.edges[0].node.frontmatter.title}
            placeholder="blurred"
          />
        </div>
        <div className="relative md:absolute text-black md:text-white top-0 left-0 w-full h-full">
          <div className="container py-5 md:mt-36">
            <h1 className="font-light text-2xl md:text-4xl xl:text-5xl">
              {data.allMdx.edges[0].node.frontmatter.masthead_line1 !== ""}
              <span className="inline md:block">
                {data.allMdx.edges[0].node.frontmatter.masthead_line1}
                {data.allMdx.edges[0].node.frontmatter.masthead_line2 !==
                  "" && <span className="inline">&nbsp;</span>}
              </span>
              {data.allMdx.edges[0].node.frontmatter.masthead_line2 !== "" && (
                <span className="inline md:block md:mt-1">
                  {data.allMdx.edges[0].node.frontmatter.masthead_line2}
                </span>
              )}
            </h1>
            {data.allMdx.edges[0].node.frontmatter.cta !== "" && (
              <span className="block mt-6">
                <Link to={data.allMdx.edges[0].node.frontmatter.cta_href}>
                  <BtnPrimary cta={data.allMdx.edges[0].node.frontmatter.cta} />
                </Link>
              </span>
            )}
          </div>
        </div>
      </div>

      {/* Home Highlights */}
      <div className="relative grid grid-cols-1 md:grid-cols-2 gap-1.5 mt-1.5 px-1.5 md:px-0">
        {/* Home Highlights -- LEFT */}
        <div className="relative">
          <GatsbyImage
            image={getImage(
              data.allMdx.edges[0].node.frontmatter.highlight_left
            )}
            alt={data.allMdx.edges[0].node.frontmatter.highlight_left_title}
            placeholder="blurred"
          />
          <div className="absolute w-full h-full top-0 left-0 p-5 md:p-5 lg:p-12">
            <h2 className="text-xl md:text-xl lg:text-3xl text-white font-light mb-3">
              {data.allMdx.edges[0].node.frontmatter.highlight_left_title}
            </h2>
            <Link
              to="/servicebooking/"
              className="btn-gtm"
              data-gtm-category="Homepage_Highlights"
              data-gtm-action="test_action"
              data-gtm-label="Learn more"
            >
              <BtnTransparent cta="Learn more" />
            </Link>
          </div>
        </div>
        {/* Home Highlights -- RIGHT */}
        <div className="relative">
          <GatsbyImage
            image={getImage(
              data.allMdx.edges[0].node.frontmatter.highlight_right
            )}
            alt={data.allMdx.edges[0].node.frontmatter.highlight_right_title}
            placeholder="blurred"
          />
          <div className="absolute w-full h-full top-0 left-0 p-5 md:p-5 lg:p-12">
            <h2 className="text-xl md:text-xl lg:text-3xl text-white font-light mb-3">
              {data.allMdx.edges[0].node.frontmatter.highlight_right_title}
            </h2>
            <Link
              className="btn-gtm"
              data-gtm-category="Homepage_Highlights"
              data-gtm-action="test_action"
              data-gtm-label="Learn more"
              to="/opening-deals/"
            >
              <BtnTransparent cta="Learn more" />
            </Link>
          </div>
        </div>
      </div>

      <section className="section sectionFirst">
        <div className="container py-10">
          <h2 className="text-2xl lg:text-4xl font-light uppercase">
            Featured Models.
          </h2>

          <div className="carSeries relative mt-5">
            <div className="cars relative z-20 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
              {modelsJson.lines.map(series => {
                return (
                  <>
                    {series.cars.map(car => {
                      return (
                        car.enabled === true &&
                        car.home_featured === true && (
                          <ModelsCard data={car} forHome={true} />
                        )
                      )
                    })}
                  </>
                )
              })}
            </div>
          </div>

          <div className="mt-5 md:mt-8">
            <Link
              className="btn-gtm flex justify-center w-full"
              data-gtm-category="Homepage_View all BMW models"
              data-gtm-action="Clicked_View all BMW models"
              data-gtm-label="View all BMW models"
              to="/all-models/"
            >
              <BtnPrimary cta="View all BMW models" />
            </Link>
          </div>
        </div>
      </section>
      <script
        dangerouslySetInnerHTML={{
          __html: `window._adftrack = Array.isArray(window._adftrack) ? window._adftrack : (window._adftrack ? [window._adftrack] : []);
          window._adftrack.push({
              HttpHost: 'asia.adform.net',
              pm: 1824002
          });
          (function () { var s = document.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = 'https://s2.adform.net/banners/scripts/st/trackpoint-async.js'; var x = document.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x); })();`,
        }}
      ></script>
      <script
        dangerouslySetInnerHTML={{
          __html: `window.teads_e = window.teads_e || []; window.teads_buyer_pixel_id = 4860;`,
        }}
      ></script>

      <noscript>
        <p className="border-0 m-0 p-0">
          <img
            src="https://asia.adform.net/Serving/TrackPoint/?pm=1824002"
            width="1"
            height="1"
            alt=""
          />
        </p>
      </noscript>
      <script
        dangerouslySetInnerHTML={{
          __html: `
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '608633623033362');
          fbq('track', 'PageView');`,
        }}
      ></script>
      <noscript>
        <img
          height="1"
          width="1"
          className="hidden"
          src="https://www.facebook.com/tr?id=608633623033362&ev=PageView&noscript=1"
        />
      </noscript>
    </Layout>
  )
}
export const query = graphql`
  query {
    allMdx(filter: { frontmatter: { page_id: { eq: "index" } } }) {
      edges {
        node {
          frontmatter {
            slug
            page_id
            date
            title
            description
            masthead_line1
            masthead_line2
            cta
            cta_href
            masthead_desktop {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  blurredOptions: { width: 300 }
                  placeholder: BLURRED
                  breakpoints: [1080, 1366, 1920]
                  outputPixelDensities: [1, 1.5, 2]
                )
              }
            }
            masthead_mobile {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  blurredOptions: { width: 150 }
                  placeholder: BLURRED
                  breakpoints: [650]
                  outputPixelDensities: [2]
                )
              }
            }
            highlight_left {
              childImageSharp {
                gatsbyImageData(
                  blurredOptions: { width: 100 }
                  placeholder: BLURRED
                )
              }
            }
            highlight_left_title
            highlight_right {
              childImageSharp {
                gatsbyImageData(
                  blurredOptions: { width: 100 }
                  placeholder: BLURRED
                )
              }
            }
            highlight_right_title
          }
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default IndexPage
const seoDetails = {
  title: "Eurokars Auto",
  description: "Singapore has a new source of Joy. ",
  keywords:
    "BMW, BMW M, BMW i, BMW Test Drive, BMW Financing Options, BMW Latest Deals, BMW Servicing, BMW Warranty, BMW Cars, BMW Register Interest, BMW Contact Us, Eurokars, Eurokars Auto, BMW Models, BMW Eurokars Auto",
  image: "og-eka-home.jpg?r1",
}
export const Head = ({ data }) => (
  <>
    <title>{seoDetails.title}</title>
    <meta property="og:type" content="website" />
    <meta
      name="description"
      property="description"
      content={seoDetails.description}
    ></meta>
    <meta
      name="og:description"
      property="og:description"
      content={seoDetails.description}
    ></meta>
    <meta
      name="keywords"
      property="keywords"
      content={seoDetails.keywords}
    ></meta>
    <meta
      name="og:url"
      property="og:url"
      content={`${process.env.GATSBY_BASE_URL}`}
    ></meta>
    <meta
      name="og:image"
      property="og:image"
      content={`${process.env.GATSBY_BASE_URL}/img/${seoDetails.image}`}
    ></meta>

    <script
      async
      src="https://www.googletagmanager.com/gtag/js?id=AW-782099393"
    ></script>
    <script
      async
      src="https://www.googletagmanager.com/gtag/js?id=G-8FJZCNFV3R"
    ></script>
    <script
      async
      src="https://www.googletagmanager.com/gtag/js?id=GTM-M7DKTD9"
    ></script>

    <script
      type="text/javascript"
      src="https://p.teads.tv/teads-fellow.js"
      async="true"
    ></script>
  </>
)
